/*
 * @Description: 
 * @Version: 1.0
 * @Author: caoyuheng
 * @Date: 2022-11-28 19:44:46
 * @LastEditors: caoyuheng
 * @LastEditTime: 2023-06-25 20:18:39
 * @FilePath: /doctor_plat_wx/src/store/index.js
 * Copyright (C) 2023 caoyuheng. All rights reserved.
 */
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    url:'https://www.admin.lingnan360.net:446/',
    // url:"http://doctor.realid315.com/",
    type:'岭南伙伴',//性取向提示类型
    pageType:'1'
  },
  getters: {
  },
  mutations: {
    SET_PAGETYPE(state,type){
      state.pageType=type
    }
  },
  actions: {
  },
  modules: {
  }
})
