<template>
  <div>
    <router-view />
    <van-tabbar route v-model="active" v-if="$route.meta.showTab">
      <van-tabbar-item replace @click="clickBar('0')" to="/index.html" icon="home-o">
        <span :class="active == 0 ? 'text1' : 'text2'">主页</span>
        <template #icon="props">
          <img
            :src="active == 0 ? require('../src/assets/tabbar/index_active.png') : require('../src/assets/tabbar/index.png')"
            alt="">
        </template>
      </van-tabbar-item>
      <van-tabbar-item replace @click="clickBar('1')" to="/share.html" icon="home-o">
        <span :class="active == 1 ? 'text1' : 'text2'">咨询分享</span>
        <template #icon="props">
          <img
            :src="active == 1 ? require('../src/assets/tabbar/share_active.png') : require('../src/assets/tabbar/share.png')"
            alt="">
        </template>
      </van-tabbar-item>
      <van-tabbar-item replace to="/user.html" @click="clickBar('2')" icon="home-o">
        <span :class="active == 2 ? 'text1' : 'text2'">个人中心</span>
        <template #icon="props">
          <img :src="active == 2 ? require('../src/assets/tabbar/user_active.png') : require('../src/assets/tabbar/user.png')"
            alt="">
        </template>
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>
<script>
import { mapState } from 'vuex';
export default {
  name: "app",
  data() {
    return {
      active: 0
    }
  },
  computed:{
    ...mapState(['pageType'])
  },
  created(){
    if(localStorage.getItem('pageIndex')){
      this.active=localStorage.getItem('pageIndex')
      setTimeout(()=>{
        localStorage.removeItem('pageIndex')
      },100)
    }
  },
  methods:{
    clickBar(index){
      localStorage.setItem('pageIndex',index)
      if(index==0){
        if(this.pageType=='2'){
          this.$store.commit('SET_PAGETYPE','1')
        }
      }
      console.log(index,'xxx')
    }
  }
};
</script>
<style>
body {
  padding-bottom: 64px;
}
.text1 {
  font-size: 11px;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 400;
  color: #04C160;
  line-height: 13px;
}

.text2 {
  font-size: 11px;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 400;
  color: #334356;
  line-height: 13px;
}

div {
  box-sizing: border-box;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  /* color: #2c3e50; */
}
body {
  background-color: rgba(249, 249, 249, 1);
}
nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
